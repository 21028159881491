<template>
  <div class="checkout-page">
    <div class="cart-card">
      <div class="content-box-type2">
        <div class="content2-left">
          <div class="left-form-box">
            <div class="common-title">
              <img src="../../assets/images//Mall/info.png" alt="" />
              <div>Account & Billing Details</div>
            </div>
            <div class="form-box">
              <el-row class="c-row">
                <el-col class="c-col" :span="12">
                  <div class="title">
                    First Name<span style="color: red">*</span>
                  </div>
                  <div class="input-box">
                    <input
                      style="text-align: left"
                      class="chekout-input"
                      type="text"
                      v-model="formData.firstName"
                      placeholder="" />
                  </div>
                </el-col>
                <el-col class="c-col" :span="12">
                  <div class="title">
                    Last Name<span style="color: red">*</span>
                  </div>
                  <div class="input-box">
                    <input
                      style="text-align: left"
                      class="chekout-input"
                      type="text"
                      v-model="formData.lastName"
                      placeholder="" />
                  </div>
                </el-col>
              </el-row>
              <el-row class="c-row">
                <el-col class="c-col" :span="12">
                  <div class="title">
                    Telephone<span style="color: red">*</span>
                  </div>
                  <div class="input-box">
                    <input
                      style="text-align: left"
                      class="chekout-input"
                      type="text"
                      placeholder=""
                      v-model="formData.telephone" />
                  </div>
                </el-col>
                <el-col class="c-col" :span="12">
                  <div class="title">
                    Address<span style="color: red">*</span>
                  </div>
                  <div class="input-box">
                    <input
                      style="text-align: left"
                      class="chekout-input"
                      type="text"
                      placeholder="Billing address must match credit card address for approval."
                      v-model="formData.address" />
                  </div>
                </el-col>
              </el-row>
              <el-row class="c-row">
                <el-col class="c-col" :span="12">
                  <div class="title">Post Code</div>
                  <div class="input-box">
                    <input
                      style="text-align: left"
                      class="chekout-input"
                      type="text"
                      placeholder="postCode"
                      v-model="formData.postCode" />
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="left-form-email-box">
            <el-row class="c-row">
              <el-col class="c-col" :span="12">
                <!-- <div class="flex-box"> -->
                <div class="email-box">
                  <div class="title">
                    Email<span style="color: red">*</span>
                  </div>
                  <div class="input-box">
                    <input
                      style="text-align: left"
                      class="chekout-input"
                      type="text"
                      v-model="formData.email"
                      placeholder="email" />
                  </div>
                </div>
                <!-- <div class="cart-totle-btn-box">
                      <el-button class="car-buy-btn"  @click="resetEmail()"

                        >reset</el-button
                      >
                    </div> -->
                <!-- </div> -->
              </el-col>
            </el-row>
          </div>
          <div class="left-order-box">
            <div class="cart-table">
              <el-table
                :data="carList"
                stripe
                style="width: 100%"
                :header-cell-style="{
                  background: '#ffb100',
                  color: '#fff',
                }">
                <el-table-column prop="pic" label="image" style="width: 20%">
                  <template slot-scope="scope">
                    <div class="products-pic">
                      <img :src="scope.row.goods?.image" alt="" width="100%" />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="Products"
                  label="Products"
                  style="width: 30%">
                  <template slot-scope="scope">
                    <div class="products-des">
                      {{ scope.row.goods?.title }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="Price" label="Price" style="width: 10%">
                  <template slot-scope="scope">
                    <div class="products-Price">
                      ${{
                        isVip
                          ? scope.row.goods?.price
                          : scope.row.goods?.marketprice
                      }}
                      *{{ scope.row?.nums }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="Subtotal"
                  label="Subtotal"
                  style="width: 20%">
                  <template slot-scope="scope">
                    <div class="products-Price">
                      ${{
                        isVip
                          ? (scope.row.goods?.price * scope.row.nums).toFixed(1)
                          : (
                              scope.row.goods?.marketprice * scope.row.nums
                            ).toFixed(1)
                      }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="cart-totle">
              <div class="good-row-left">total:</div>
              <div class="good-row-right">${{ carTotalPrice }}</div>
            </div>
          </div>
        </div>
        <div class="content2-right">
          <div class="common-title p-0-60">
            <img src="../../assets/images//Mall/email.png" alt="" />
            <div>Payment Method</div>
          </div>
          <div class="pay-radio">
            <el-radio-group v-model="payMethod" ref="radio">
              <div class="pay-img-box" @click="payclick()">
                <el-radio class="radio-item" :label="3"
                  >credit card,gpay,apple pay,buy now pay later</el-radio
                >
                <img src="../../assets/images/pc/payicon2.png" alt="" />
              </div>
              <div class="pay-img-box" @click="payclick()">
                <el-radio class="radio-item" :label="6"
                  >Paypal Checkout Express
                </el-radio>
                <img src="../../assets/images/pc/payicon1.png" alt="" />
              </div>
              <div class="pay-img-box" @click="payustdclick()">
                <el-radio class="radio-item" :label="9"
                  >Cryptocurrency <img src="" alt=""
                /></el-radio>
                <img src="../../assets/images/pc/payicon3.png" alt="" />
              </div>
            </el-radio-group>
          </div>
          <div class="remarks-box">
            <div class="remarks-title">Add Comments About Your Order</div>
            <div class="textarea-box">
              <el-input
                resize="none"
                class="input-box"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 5 }"
                placeholder="Please make sure your infornation is accurate including Zip Code and Country to avoid issues with the payment."
                v-model="formData.memo">
              </el-input>
            </div>
          </div>
          <div class="cart-totle-btn-box">
            <el-button
              class="car-buy-btn"
              v-if="userInfo?.money * 1 >= carTotalPrice * 1"
              @click="orderSubmitOnClick"
              >checkout</el-button
            >
            <el-button class="car-buy-btn-dis" v-else>checkout</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { getOrderListApi, orderPayApi, orderSubmitApi } from "@/network/api.js";
import { validateEmail } from "@/utils/common.js";
export default {
  name: "cart",
  data() {
    return {
      titleName: "Billing details",
      OrderList: [],
      order_sn: "",
      formData: {
        firstName: "",
        lastName: "",
        telephone: "",
        address: "",
        postCode: "",
        city: "",
        email: "",
        memo: "",
      },
      payMethod: 3,
    };
  },
  computed: {
    ...mapGetters(["isVip", "isLogin"]),
    ...mapState(["carList", "userInfo"]),
    carTotalPrice() {
      let totle = 0;
      this.carList.forEach((item) => {
        if (this.isVip) {
          totle += item?.goods?.price * item?.nums;
        } else {
          totle += item?.goods?.marketprice * item?.nums;
        }
      });
      return totle.toFixed(1);
    },
  },

  created() {
    this.getOrderList();
  },
  watch: {
    type: {
      handler() {
        this.ReFreshUserInfo();
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    if (this.userInfo) {
      this.formData.email = this.userInfo.email;
    }
    this.$refs.radio.$children.forEach((item) => {
      item.$refs.radio.removeAttribute("aria-hidden");
    });
  },
  methods: {
    payclick() {
      this.$message.warning("Under maintenance");
    },
    payustdclick() {
      if (!this.isLogin) {
        this.$message.error("Please login first");
        return false;
      }
      this.$router.push({ path: "/User" });
    },
    ...mapActions(["getCartList", "ReFreshUserInfo"]),
    ...mapMutations(["setShowTipPopup"]),
    async getOrderList() {
      const res = await getOrderListApi();
      this.OrderList = res.data;
      console.log("订单列表", res.data.Items);
    },
    gobacktoshopping() {
      this.$router.push("/Office");
    },
    handleChangeQuantity(value) {
      console.log(value);
    },
    // toGoodDetail(scope) {
    //   this.$router.push({ name: "MallDetail" });
    // },
    resetEmail() {
      this.formData.email = "";
    },
    async orderSubmitOnClick() {
      if (
        !this.formData.email ||
        !this.formData.firstName ||
        !this.formData.lastName
      ) {
        this.$message.error("name,email required");
        return false;
      }
      if (!this.formData.telephone || !this.formData.address) {
        this.$message.error("telephone,address required");
        return false;
      }
      if (!validateEmail(this.formData.email)) {
        this.$message.error("Email is not valid");
        return false;
      }
      const params = {
        ids: this.carList.map((item) => item.id),
        ...this.formData,
      };
      const res = await orderSubmitApi(params).catch(() => {});
      this.order_sn = res.data?.order_sn;

      this.getCartList();
      this.orderPayFun(res.data?.order_sn);
      this.toUser();
    },
    toUser() {
      if (!this.isLogin) {
        this.$message.error("Please login first");
        return false;
      }
      this.$router.push("/User");
    },
    async orderPayFun() {
      if (this.order_sn) {
        const params = {
          order_sn: this.order_sn,
        };
        try {
          const res = await orderPayApi(params);
          this.ReFreshUserInfo();
          this.type += 1;
        } catch (error) {
          this.$message.error(error);
        }
      } else {
        this.$message.error("error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-page {
  .cart-card {
    padding: 1.1rem 2.23rem 1.8rem;
    .p-0-60 {
      padding: 0 0.6rem;
    }
    .common-title {
      font-size: 0.18rem;
      height: 0.4rem;
      line-height: 0.4rem;
      color: #fff;
      background-color: #ffb100;
      padding: 0.02rem 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0.2rem;
      img {
        width: 30px;
        height: 30px;
        padding-right: 0.1rem;
      }
    }
    .content-box-type2 {
      display: flex;
      align-items: flex-start;
      .c-row {
        margin-bottom: 0.18rem;

        .c-col {
          padding-right: 20px;
          .title {
            margin-bottom: 0.08rem;
            font-size: 0.16rem;
            color: #000;
          }

          .input-box {
            width: 100%;
            height: 0.4rem;
            border-radius: 0.04rem;
            border: 1px solid #cdcdcd;
            background: #fff;

            .chekout-input {
              border: 0;
              width: 100%;
              height: 100%;
              background: unset;
              text-align: center;
              font-size: 0.12rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .textarea-box {
            width: 100%;
            border-radius: 0.04rem;
            background: #fff;
            .el-textarea__inner,
            .el-textarea {
              border: 0;
              width: 100%;
              height: 100%;
              background: unset;
              text-align: left;
              font-size: 0.2rem;
              color: #000;
            }
            .el-textarea__inner::placeholder {
              font-size: 0.2rem;
              color: #ccc;
            }
          }
        }
      }

      .content2-left {
        width: 8.76rem;
        flex-shrink: 0;
        margin-right: 0.32rem;

        .left-form-box {
          border: 1px solid rgba(36, 75, 120, 0.2);
          border-radius: 5px;
        }

        .form-box {
          margin-bottom: 0.2rem;
          padding: 0.5rem 0.6rem;
        }

        .left-form-email-box {
          padding: 0.5rem 0;
          .flex-box {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 0.2rem;
          }

          .cart-totle-btn-box {
            padding: 10px 0;
            text-align: center;
            .car-buy-btn {
              background-color: #ffb100;
              color: #fff;
              // font-weight: bold;
              padding: 12px 24px;
            }
            .car-buy-btn:hover {
              background-color: #0e1422;
            }
          }
        }

        .left-order-box {
          border: 1px solid rgba(36, 75, 120, 0.2);
          border-radius: 5px;
          border-collapse: collapse;
          .cart-table {
            width: 100%;
            // border: 1px solid rgba(36, 75, 120, 0.2);
            border-radius: 5px;
            .products-pic {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 10px;

              .circle-delete-box {
                width: 25px;
                height: 25px;
                background-color: #0e1422;
                border: 1px solid #0e1422;
                color: #fff;
                border-radius: 50%;
                text-align: center;
                line-height: 25px;
                font-size: 12px;
                cursor: pointer;
              }
              .circle-delete-box:hover {
                cursor: pointer;
                background-color: #d63637;
                border: 1px solid #d63637;
              }

              img {
                width: 65px;
                height: 80px;
                margin: 0 0.2rem;
                flex-shrink: 0;
              }
            }
            .products-des {
              color: #000;
            }
            // .products-des:hover {
            //   color: #ffb100;
            // }
            .products-Price {
              color: #ffb100;
              font-weight: bold;
            }
          }

          .cart-totle {
            display: flex;
            justify-content: left;
            font-size: 16px;
            height: 0.3rem;
            line-height: 0.3rem;
            .good-row-left {
              margin-left: 10px;
            }
          }
        }
      }

      .content2-right {
        flex-grow: 1;
        box-sizing: border-box;
        border: 1px solid rgba(36, 75, 120, 0.2);
        border-radius: 5px;

        .pay-radio {
          padding: 0.2rem 0.6rem;
          .el-radio-group {
            display: flex;
            flex-direction: column;
          }
          .radio-item {
            display: block;
            font-size: 0.16rem;
            padding: 0.1rem 0;
          }
          .pay-img-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
              height: 0.22rem;
            }
          }
        }
        .remarks-box {
          padding: 0.2rem 0.6rem;
          .remarks-title {
            color: #666;
            font-weight: 600;
          }
        }
        .cart-totle-btn-box {
          padding: 10px 0;
          text-align: center;
          .car-buy-btn {
            background-color: #ffb100;
            color: #fff;
            // font-weight: bold;
            padding: 12px 24px;
          }
          .car-buy-btn-dis {
            background: #eee;
            color: #ccc;
            padding: 12px 24px;
          }
          .car-buy-btn:hover {
            background-color: #0e1422;
          }
        }

        .good-row-box {
          margin-bottom: 0.6rem;

          .good-row {
            margin-bottom: 0.36rem;
            font-size: 0.2rem;
          }
        }

        .price-row {
          font-size: 0.2rem;
          font-weight: 500;
          margin-bottom: 0.18rem;
        }

        .paytab {
          width: 4.53rem;
          height: 0.5rem;
          margin: 0.4rem auto;
          display: flex;
          align-items: center;
          .payicon {
            width: 1.8rem;
            height: 0.5rem;
            margin-right: 0.2rem;

            img {
              width: 100%;
            }
          }
        }
        .paytab-tips {
          font-size: 0.14rem;
          color: #000;
          margin-bottom: 0.2rem;
          padding: 0 0.2rem 0.2rem;
        }
        .bottom-btn-box {
          .btn {
            width: 2.29rem;
            height: 0.5rem;
            border-radius: 0.04rem;
            background: rgba(0, 118, 255, 1);
            font-size: 0.18rem;
            font-weight: 900;
            color: #fff;
          }
          .btn-gray {
            background: #eee;
            color: #ccc;
          }
        }
      }
    }
  }
}
</style>
<style>
.el-table thead {
  background-color: #fff0cf;
}

.el-input::placeholder {
  font-size: 14px;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #ffb100;
}
.el-radio__input.is-checked .el-radio__inner {
  background: #ffb100;
  border-color: #ffb100;
}
.el-radio__inner:hover {
  color: #ffb100;
}
</style>
